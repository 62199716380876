<template>
	<div>
		<b-card-group>
			<b-card
				:header="title"
				header-bg-variant="dark"
				header-text-variant="white h4">
				<router-view />
			</b-card>
		</b-card-group>
	</div>
</template>
<script>

import DocumentTitle from '@/mixins/DocumentTitle';

export default {
	name: 'Header',
	mixins: [DocumentTitle],
	data() {
		return {
			titleKey: undefined,
			titleParam: undefined,
		};
	},
	computed: {
		title() {
			return this.getTitle(this.titleKey, this.titleParam);
		},
	},
};
</script>
